<template>
  <WaitWrapper :loading="isGlossaryLoading">
    <div>
      <section class="d-flex align-center justify-space-between">
        <Header title="Glossary" />
      </section>
    </div>
    <v-tabs v-model="tab">
      <v-tab
        v-for="(item, index) in glossary"
        :key="index"
        class="no-background"
        data-ga="glossary_page_tab"
        >{{ index }}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab" class="no-background">
      <v-tab-item v-for="(item, index) in glossary" :key="index">
        <div class="d-flex justify-end mr-4">
          <v-text-field
            v-model="filter[index]"
            label="Search..."
            solo
            dense
            hide-details
            clearable
            append-icon="mdi-magnify"
            class="no-border"
            :data-ga="`glossary_page_tab_${index.replaceAll(' ', '_')}_search`"
            style="max-width: 25%"
          ></v-text-field>
        </div>
        <DataTable
          className="no-background glossary-table"
          itemKey="site_id"
          :search="filter[index]"
          :showSelect="false"
          :headers="headers"
          :items="item"
          :items-per-page="50"
          :loading="isGlossaryLoading"
        />
      </v-tab-item>
    </v-tabs-items>
  </WaitWrapper>
</template>
<script>
import WaitWrapper from "@/components/WaitWrapper";
import Header from "@/components/Header";
import DataTable from "@/components/DataTable";
import { mapActions, mapState } from "vuex";

export default {
  name: "Glossary",
  components: {
    DataTable,
    Header,
    WaitWrapper,
  },
  data() {
    return {
      filter: [],
      defaultHeaders: [
        { text: "Name", sortable: true, value: "name", width: "25%" },
        {
          text: "Description",
          sortable: true,
          value: "description",
          width: "75%",
        },
      ],
      signalDefsHeaders: [
        { text: "Name", sortable: true, value: "name", width: "25%" },
        {
          text: "Description",
          sortable: true,
          value: "description",
          width: "65%",
        },
        {
          text: "Sample period",
          sortable: true,
          value: "sample_period",
          width: "10%",
        },
      ],
      tab: 0,
    };
  },
  computed: {
    ...mapState({
      glossary: (state) => state.glossary.glossary,
      isGlossaryLoading: (state) => state.glossary.loading.getGlossary,
    }),
    headers() {
      if (this.tab === 3) {
        return this.signalDefsHeaders;
      } else {
        return this.defaultHeaders;
      }
    },
  },
  async beforeMount() {
    if (this.glossary?.length === 0) {
      await this.getGlossary();
    }
  },
  methods: {
    ...mapActions({
      getGlossary: "glossary/getGlossary",
    }),
  },
};
</script>
<style lang="scss" scoped>
// TODO: Find an alternative to this as this is being deprecated
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  cursor: pointer;
}
input[type="search"] {
  -webkit-appearance: none;
}
</style>

<style>
.glossary-table table {
  table-layout: fixed;
}
.v-tabs-bar {
  background-color: transparent !important;
}
</style>
